import ActionTypes from '../actions/actionTypes';


const initialState = {
    submitted: false,
    loading: false,
    error: false,
}

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case ActionTypes.SUBMISSION_LOADING:
            return {
                ...state,
                submitted: true,
                loading: true,
                error: false
            }
        case ActionTypes.SUBMISSION_SUCCESS:
            return {
                ...state,
                loading: false
            }
        case ActionTypes.SUBMISSION_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        default:
            return state
    }
};
