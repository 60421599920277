import { Container } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import logo from '../../../assets/kenzielogo.png'

const HeaderContainer = withStyles({
  root: {
    position: 'absolute',
    left: '110px',
    top: '49px',
    padding: '10px',
    display: 'flex',
    alignItems: 'center',
    '@media only screen and (max-width: 600px)': {
      left: '20px',
    },
    '& img': {
      width: '33px',
      height: '35px'
    },
    '& .main': {
      backgroundColor: '#00306F',
      width: '53px',
      height: '54px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '& .logoText': {
      fontSize: '22px',
      fontWeight: '600',
      color: '#00356b'
    },
    '& .link': {
      textDecoration: 'none'
    },
    '& .text': {
      marginLeft: '10px',
      textDecoration: 'none'
    }
  }
})(Container);

const Header = () => {
  return (
    <HeaderContainer>
      <div className="main">
        <a className="link" href="/"><img alt="logo" src={logo} /></a>
      </div>
      <a className="text" href="/"><div className='logoText'>User Experience Skills Check</div></a>
    </HeaderContainer>
  );
}

export default Header;
