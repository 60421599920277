import React from 'react';
import Logo from '../../../assets/ka-logo-full.svg';
import UCSD from '../../../assets/UCSD.png';
import Vertical from '../../../assets/vertical-line.svg';
import { Container } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const HeaderContainer = withStyles({
  root: {
    position: 'absolute',
    top: '40px',
    width: '100%',
    maxWidth: '100%',
    '& .logosContainer': {
      position: 'absolute',
      left: '80px',
      display: 'flex',
      alignItems: 'center',
      '@media only screen and (max-width: 1070px)': {
        top: '-15px',
        left: '0px',
        position: 'relative',
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
    '& .main': {
      width: '80px',
      height: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '& .headerText': {
      height: '54px',
      lineHeight: '54px',
      '@media only screen and (max-width: 1070px)': {
        marginTop: '-20px',
      }
    },
    '& .logoText': {
      fontSize: '28px',
      fontWeight: '600',
      color: '#00356b',
      textAlign: 'center',
      verticalAlign: 'middle',
      width: '100%',
      '@media only screen and (max-width: 1170px) and (min-width: 1070px)': {
        textAlign: 'right',
      },
      '@media only screen and (max-width: 520px)': {
        fontSize: '24px',
      }
    },
    '& .link': {
      textDecoration: 'none'
    },
    '& .divider': {
      paddingLeft: '1.75em',
      paddingRight: '1.75em',
    },
    '& .ucsd': {
      paddingBottom: '0.1em',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& img': {
        width: '150px',
        height: '50px',
        wrap: 'none',
      }
    }
  },
})(Container);

const Header = () => {
  return (
    <HeaderContainer>
      <div className="logosContainer">
        <div className="ucsd">
          <a className="link" href="/"><img className="ucsd" alt="ucsd" src={UCSD}></img></a>
        </div>
        <div className="divider">
          <img alt="vertical-line" src={Vertical} />
        </div>
        <div className="main">
          <a className="link" href="/"><img alt="logo" src={Logo}></img></a>
        </div>
      </div>
      <div className="headerText">
        <a className="link" href="/"><div className='logoText'>User Experience Skills Check</div></a>
      </div>
    </HeaderContainer>
  );
}


export default Header;