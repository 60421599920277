import PropTypes from 'prop-types';
import React, { Component } from 'react';
import pageConnect from '../../../redux/pageConnect';
import Helpers from '../../../helpers';

import Card from '@material-ui/core/Card';

import Header from '../../../components/Header';
import NavigationBar from '../../../components/NavigationBar';
import ShortAnswerQuestion from '../../../components/Questions/ShortAnswer';
import LongAnswerQuestion from '../../../components/Questions/LongAnswer'
import NavigationControls from '../../../components/NavigationControls';
import getQuestionError from '../../../helpers/errorChecker';
import { validateWebsite } from '../../../helpers/validation';

const exerciseQuestions = [
  {
    required: true,
    key: 'interestUrl',
    label: 'URL of website',
    placeholder: 'www.website.com',
    type: 'url',
    questionType: 'short',
  },
  {
    required: true,
    key: 'interestReason',
    label: 'What is the most interesting thing about this website?',
    placeholder: 'I like the...',
    questionType: 'long'
  },
  // Need to look into creating a question type with multiple short answers
  {
    required: true,
    key: 'websiteDoingWell',
    label: 'What are three things this website is doing well?',
    placeholder: 'Explanation',
    questionType: 'long'
  },
  // Need to look into creating a question type with multiple short answers
  {
    required: true,
    key: 'websiteCouldImprove',
    label: 'What are three ways you would improve this website?',
    placeholder: 'Explanation',
    questionType: 'long'
  },
  {
    required: true,
    key: 'websiteAudience',
    label: 'Who is this website designed for?',
    placeholder: 'It\'s designed for...',
    questionType: 'short'
  },
];


class AppCritique extends Component {


  handleNavigation(direction, block = false) {
    const appCritique = {};
    exerciseQuestions.forEach((question) => {
      let item = document.getElementById(question.key).value
      appCritique[question.key] = item;
      appCritique[question.key + 'Error'] = !item ? true : false;
      if (question.key === 'interestUrl') {
        appCritique[question.key + 'ErrorValidWebsite'] = validateWebsite(item)
      }
    });

    this.props.saveExercisesData({ appCritique });

    if (!block) {
      if (direction === 'next') {
        this.props.allowNext();
      } else if (direction === 'prev') {
        this.props.allowPrev();
      }
    }
  }

  render() {
    // const onBlur = (this.props.error ? () => this.handleNavigation('', true) : () => { });
    const onBlur = () => {};

    return (
      <div>
        <Header />
        <div className="Introduction">
          <NavigationBar
            changeRoute={this.props.changeRoute}
            location={this.props.location}
            onNavigation={() => this.handleNavigation()}
          />
          <Card className="Card">
            <div className="CardHeaderText">App Critique</div>
            <span>Key Skills: critical thinking</span>
            <br />
            <span style={{ fontSize: "14px" }}>~15 minutes</span>

            <p className="ParagraphText" style={{ paddingTop: "30px", paddingBottom: "30px" }}>
              For this exercise we will ask you to critique the website of your choice.
              Choose a website you have seen before. Take a few minutes to look over
              the website then fill out the form below.
            </p>

            {exerciseQuestions.map((question, index) => {
              let errors = getQuestionError(question, 'exercises.appCritique', this.props)
              const value = Helpers.get(this.props, `exercises.appCritique.${question.key}`);

              if (question.questionType === 'short') {
                return (
                  <ShortAnswerQuestion

                    error={this.props.error ? errors[`${question.key}Error`] || errors[`${question.key}ErrorValidWebsite`] : false}
                    helperText={this.props.error ? (errors[`${question.key}Error`] && 'Required') || (errors[`${question.key}ErrorValidWebsite`] && 'please enter valid website url') : ''}
                    key={`app_critique_question_${index}`}
                    required={question.required}
                    questionKey={question.key}
                    label={question.label}
                    placeholder={question.placeholder}
                    value={value}
                    onBlur={onBlur}
                  />
                )
              } else {
                return (
                  <LongAnswerQuestion
                    error={question}
                    object={errors}
                    key={`background_question_${index}`}
                    required={question.required}
                    questionKey={question.key}
                    label={question.label}
                    placeholder={question.placeholder}
                    value={value}
                    onBlur={onBlur}
                  />
                )
              }
            })}

            <NavigationControls
              nav={this.props.nav}
              changeRoute={this.props.changeRoute}
              allowNext={this.props.allowNext}
              allowPrev={this.props.allowPrev}
              denyNext={this.props.denyNext}
              denyPrev={this.props.denyPrev}
              location={this.props.location}
              nextLabel="Next"
              prevLabel="Previous"
              onNextClick={() => this.handleNavigation('next')}
              onPrevClick={() => this.handleNavigation('prev')}
            />
          </Card>
        </div>
      </div>
    );
  }
}

AppCritique.propTypes = {
  changeRoute: PropTypes.func,
  exercises: PropTypes.shape({
    appCritique: PropTypes.object,
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string,
  })
}

export default pageConnect(AppCritique);
