function validateEmail(email) {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return !re.test(String(email).toLowerCase());
}

function validateWebsite(website) {
  var re = /(http[s]?:\/\/)?[^\s(["<,>]*\.[^\s[",><]*/;
  return !re.test(String(website).toLowerCase());
}

export {
  validateEmail,
  validateWebsite
};