import Actions from './actions';

const Form = Actions.Form;
const Marketing = Actions.Marketing;
const Nav = Actions.Nav;
const Upload = Actions.Upload;
const Submit = Actions.Submit

const mapDispatchToProps = (dispatch) => ({
  changeRoute: (route) => dispatch(Nav.changeRoute(route)),
  saveApplicantData: (applicant) => dispatch(Form.saveApplicantData(applicant)),
  saveBackgroundData: (background) => dispatch(Form.saveBackgroundData(background)),
  saveExercisesData: (exercise) => dispatch(Form.saveExercisesData(exercise)),
  saveImagesData: (images) => dispatch(Form.saveImagesData(images)),
  saveMarketingData: (marketing) => dispatch(Marketing.saveMarketingData(marketing)),
  saveErrorStatus: (error) => dispatch(Form.saveErrorStatus(error)),
  uploadImages: (images, email) => dispatch(Upload.uploadImages(images, email)),
  submitFinal: (submissionData) => dispatch(Submit.submitFinal(submissionData)),
  allowNext: () => dispatch(Nav.allowNext()),
  allowPrev: () => dispatch(Nav.allowPrev()),
  denyNext: () => dispatch(Nav.denyNext()),
  denyPrev: () => dispatch(Nav.denyPrev()),
});

export default mapDispatchToProps;
