import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { getPrevPath } from '../../../routes';
import Helpers from '../../../helpers';

import { SecondaryButton } from '../Default';

class PrevButton extends Component {

  handlePrevButtonClick() {
    this.props.onPrevClick();
  }

  navigatePrev() {
    const currentPath = Helpers.get(this.props, 'location.pathname');
    const prevPath = getPrevPath(currentPath);
    this.props.changeRoute(prevPath);
  }

  componentDidUpdate() {
    const allowPrev = Helpers.get(this.props, 'nav.allowPrev', false);
    if (allowPrev) {
      this.props.denyPrev();
      this.navigatePrev();
    }
  }

  render() {
    const children = this.props.children;
    return (
      <SecondaryButton onButtonClick={() => this.handlePrevButtonClick()}>
        {children}
      </SecondaryButton>
    );
  }
}

PrevButton.propTypes = {
  changeRoute: PropTypes.func,
  onPrevClick: PropTypes.func,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  nav: PropTypes.shape({
    allowNext: PropTypes.bool,
    allowPrev: PropTypes.bool,
  }),
  denyPrev: PropTypes.func,
}

export default PrevButton;
