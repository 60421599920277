import ActionTypes from './actionTypes';

export function saveApplicantData(applicant) {
  return {
    type: ActionTypes.APPLICANT_SAVE,
    applicant
  }
}

export function saveBackgroundData(background) {
  return {
    type: ActionTypes.BACKGROUND_SAVE,
    background
  }
}

export function saveExercisesData(exercises) {
  return {
    type: ActionTypes.EXERCISES_SAVE,
    exercises
  }
}

export function saveImagesData(images){
  return {
    type: ActionTypes.IMAGES_SAVE,
    images
  }
}

export function saveErrorStatus(error){
  return {
    type: ActionTypes.ERROR_STATUS,
    error
  }
}