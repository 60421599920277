// import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class NotFound extends Component {
  render() {
    return (
      <div className="NotFound">
        <h1>
          404 <small>Not Found :(</small>
        </h1>
      </div>
    );
  }
}