const ActionTypes = {
  APPLICANT_SAVE: 'applicant.save',
  BACKGROUND_SAVE: 'background.save',
  EXERCISES_SAVE: 'exercises.save',
  IMAGES_SAVE: 'images.save',
  MARKETING_SAVE: 'marketing.save',
  ERROR_STATUS: 'ERROR_STATUS',
  IMAGE_UPLOAD_SUCCESS: 'IMAGE_UPLOAD_SUCCESS',
  IMAGE_UPLOAD_FAILURE: 'IMAGE_UPLOAD_FAILURE',
  IMAGE_UPLOAD_LOADING: 'IMAGE_UPLOAD_LOADING',
  SUBMISSION_LOADING: 'SUBMISSION_LOADING',
  SUBMISSION_SUCCESS: 'SUBMISSION_SUCCESS',
  SUBMISSION_FAILURE: 'SUBMISSION_FAILURE',
  NEXT_ALLOW: 'NAV_NEXT_ALLOW',
  NEXT_DENY: 'NAV_NEXT_DENY',
  PREV_ALLOW: 'NAV_PREV_ALLOW',
  PREV_DENY: 'NAV_PREV_DENY',
};

export default ActionTypes;
