import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'
import rootReducer from './root';
import upload from './upload'
import submission from './submission'

const projectReducer = (history) => combineReducers({
  root: rootReducer,
  router: connectRouter(history),
  upload,
  submission
});

export default projectReducer;