import PropTypes from 'prop-types';
import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import { styled } from '@material-ui/core/styles';


const MyTextField = styled(TextField)({
  '& .MuiFormHelperText-contained': {
    margin: '0',
    marginTop: '3px',
    fontSize: '11px'
  }
});


class ShortAnswer extends Component {
  handleTextFieldChange(value) {
    this.setState({ answer: value });
  }


  getAnswerValue() {
    const stateValue = (this.state || {}).answer;
    const propsValue = this.props.value;
    if (document.activeElement.type === 'text') {
      return stateValue || ''
    }
    return stateValue || propsValue || '';
  }

  render() {
    const {
      label = '',
      placeholder = '',
      helperText = '',
      questionKey,
      required = false,
      type = 'text',
      error = false,
      onBlur,
    } = this.props;
    const value = this.getAnswerValue();
    return (
      <div className="ShortAnswerQuestionContainer">
        <label htmlFor={questionKey} style={{fontSize: '13px'}} className="InputLabel">
          {label}
        </label>
        <MyTextField
          error={error}
          helperText={helperText}
          type={type}
          required={required}
          id={questionKey}
          label=""
          name={questionKey}
          placeholder={placeholder}
          variant="outlined"
          className="ShortAnswerQuestion"
          size="small" // possible to switch back to normal for larger mobile inputs?
          value={value}
          onChange={(event) => this.handleTextFieldChange(event.target.value)}
          onBlur={onBlur}
        />
      </div>
    );
  }
}

ShortAnswer.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  questionKey: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.string,
}

export default ShortAnswer;
