import ActionTypes from '../actions/actionTypes';

function defaultSet(state, action, key) {
  const newState = Object.assign({}, state);

  if (typeof newState[key] === 'object') {
    newState[key] = Object.assign({}, newState[key], action[key]);
  } else {
    newState[key] = action[key];
  }

  return newState;
}

const initialState = {
  nav: {
    allowNext: false,
    allowPrev: false,
  },
  applicant: {},
  background: {},
  exercises: {
    appCritique: {},
    whiteboarding: {},
  },
  marketing: {
    program: 'default',
  }
}

const rootReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case ActionTypes.APPLICANT_SAVE: {
      return defaultSet(state, action, 'applicant');
    }

    case ActionTypes.BACKGROUND_SAVE: {
      return defaultSet(state, action, 'background');
    }

    case ActionTypes.EXERCISES_SAVE: {
      return defaultSet(state, action, 'exercises');
    }

    case ActionTypes.IMAGES_SAVE: {
      return defaultSet(state, action, 'images')
    }

    case ActionTypes.MARKETING_SAVE: {
      return defaultSet(state, action, 'marketing')
    }

    case ActionTypes.ERROR_STATUS: {
      return defaultSet(state, action, 'error')
    }

    case ActionTypes.NEXT_ALLOW: {
      return defaultSet(state, action, 'nav');
    }

    case ActionTypes.NEXT_DENY: {
      return defaultSet(state, action, 'nav');
    }

    case ActionTypes.PREV_ALLOW: {
      return defaultSet(state, action, 'nav');
    }

    case ActionTypes.PREV_DENY: {
      return defaultSet(state, action, 'nav');
    }

    default: {
      return state;
    }
  }
}

export default rootReducer;