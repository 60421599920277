import { applyMiddleware, compose, createStore } from 'redux';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import projectReducer from './reducers';
import { loadState, saveState } from './storage';

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;


export const history = createBrowserHistory();

// Not necessary if not using local storage
const persistedState = loadState();
// console.log('persistedState', persistedState);

export const store = createStore(
  projectReducer(history),
  persistedState,
  composeEnhancer(
    applyMiddleware(
      routerMiddleware(history),
      thunk,
    ),
  ),
);

store.subscribe(() => {
  const state = store.getState();
  saveState(state); // only necessary if using local storage
});

export default {
  history,
  store,
};
