export const localApiUrl = process.env.LOCAL_API_URL || 'http://localhost:3000';

export function getApiBase() {
  return process.env.REACT_APP_API_URL || `${localApiUrl}/develop`;
}

export function getApiUploadUrl() {
  return `${getApiBase()}/upload`;
}

export function getApiSubmissionUrl() {
  return `${getApiBase()}/submission`;
}

export function getApiSignUrl() {
  return `${getApiBase()}/video`;
}