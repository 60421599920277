import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Button from '@material-ui/core/Button';

class DefaultButton extends Component {
  handleDefaultButtonClick() {
    this.props.onButtonClick()
  }

  render() {
    const children = this.props.children;
    return (
      <Button
        color="primary"
        style={{border: 'solid 1px #00356b'}}
        fullWidth
        variant="contained"
        onClick={() => this.handleDefaultButtonClick()}>
        {children}
      </Button>
    );
  }
}

class SecondaryButton extends Component {
  handleDefaultButtonClick() {
    this.props.onButtonClick()
  }

  render() {
    const children = this.props.children;
    return (
      <Button
        style={{ border: 'solid 2px' }}
        color="primary"
        fullWidth
        variant="outlined"
        onClick={() => this.handleDefaultButtonClick()}>
        {children}
      </Button>
    );
  }
}

class SubmitButton extends Component {
  handleDefaultButtonClick() {
    this.props.onButtonClick()
  }

  render() {
    const children = this.props.children;
    return (
      <Button
        color="secondary"
        style={{border: 'solid 1px #f50057'}}
        fullWidth
        variant="contained"
        onClick={() => this.handleDefaultButtonClick()}>
        {children}
      </Button>
    );
  }
}



DefaultButton.propTypes = {
  onButtonClick: PropTypes.func,
}

export { DefaultButton, SecondaryButton, SubmitButton };
