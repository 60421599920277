import * as Form from './form';
import * as Marketing from './marketing';
import * as Nav from './nav';
import * as Upload from './upload';
import * as Submit from './submit';

export default {
  Form,
  Nav,
  Marketing,
  Upload,
  Submit
};
