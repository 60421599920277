import { ConnectedRouter } from 'connected-react-router';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import ReactGA from "react-ga";
import { useDispatch, useSelector } from "react-redux";

import './App.css';
import routes from './routes';
import Helpers from './helpers';
import { getProgramFromPath } from "./helpers/marketing";
import { saveMarketingData } from './redux/actions/marketing';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#00306F'
    },
    error: {
      main: '#FB7F59',
    }
  },
  typography: {
    fontFamily: "\"Avenir\", \"Nunito\", 'sans-serif'",
    fontSize: 12,
  }
});

const trackingId = 'UA-133816822-4';
process.env.REACT_APP_ENVIRONMENT === 'production' && ReactGA.initialize(trackingId);

const App = ({ history }) => {
  const dispatch = useDispatch();
  const marketing = useSelector(state => state.marketing);

  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT === 'production') {
      if (typeof window !== "undefined") {
        ReactGA.pageview(window.location.pathname);
      }
      history.listen(location => {
        ReactGA.set({ page: location.pathname }); // Update the user's current page
        ReactGA.pageview(location.pathname); // Record a pageview for the given page
      });
    }
  });

  // Check the starting path
  // If a matching program is found, persist it in the redux store
  const path = Helpers.get(history, 'location.pathname', '');
  const program = getProgramFromPath(path);
  if (program) {
    dispatch(saveMarketingData({ ...marketing, program }));
  }

  return (
    <ThemeProvider theme={theme}>
      <ConnectedRouter history={history}>
        {routes}
      </ConnectedRouter>
    </ThemeProvider>
  )
}

App.propTypes = {
  history: PropTypes.object,
};

export default App;