const mapStateToProps = state => ({
  applicant: state.root.applicant,
  background: state.root.background,
  exercises: state.root.exercises,
  images: state.root.images,
  marketing: state.root.marketing,
  error: state.root.error,
  nav: state.root.nav,
  submission: state.submission,
  upload: state.upload,
});

export default mapStateToProps;
