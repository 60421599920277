
export function saveState(state) {
  // Make a copy of state so we don't alter the original
  const saveSave = Object.assign({}, state);

  // Check state, remove unnecessary pieces that don't need local storage
  if (saveSave && typeof saveSave === 'object') {
    delete saveSave.upload;
    delete saveSave.submission;
  }

  // Attempt a save
  try {
    window.localStorage.setItem('key', JSON.stringify(saveSave));
  } catch (err) {
    console.log('Unable to save persisted state: ', err);
  }
}

export function loadState() {
  // Setup the root state look
  let state = { root: {} };
  // Attempt to load any saved values into state
  try {
    state = JSON.parse(window.localStorage.getItem('key') || '{}');
  } catch (err) {
    console.log('Unable to load persisted state: ', err);
  }
  return state;
}
