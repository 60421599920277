import ActionTypes from './actionTypes'
import { getApiUploadUrl } from './helpers/api';


export function uploadImages(imageData, email) {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.IMAGE_UPLOAD_LOADING
    })

    try {
      const apiUploadUrl = await getApiUploadUrl()
      const data = new FormData()
      data.append('fileName', imageData)
      data.append('email', email)

      const result = await fetch(apiUploadUrl, {
        method: 'POST',
        body: data
      })

      // decode result no matter what (will error if bad)
      const jsonResult = await result.json()
      console.log(jsonResult)

      // If result isn't ok, throw it
      if (!result.ok) {
        throw jsonResult;
      }

      dispatch({
        type: ActionTypes.IMAGE_UPLOAD_SUCCESS
      })
    } catch (error) {
      console.error(error)
      dispatch({
        type: ActionTypes.IMAGE_UPLOAD_FAILURE, payload: error.message
      })
    }
  }
}

