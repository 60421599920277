import ActionTypes from './actionTypes'

export { push as changeRoute } from 'connected-react-router';

export function allowNext() {
  return {
    type: ActionTypes.NEXT_ALLOW,
    nav: { allowNext: true },
  }
}

export function denyNext() {
  return {
    type: ActionTypes.NEXT_DENY,
    nav: { allowNext: false },
  }
}

export function allowPrev() {
  return {
    type: ActionTypes.PREV_ALLOW,
    nav: { allowPrev: true },
  }
}

export function denyPrev() {
  return {
    type: ActionTypes.PREV_DENY,
    nav: { allowPrev: false },
  }
}