const marketingPrograms = [
  'ucsd'
];

function getProgramFromPath(path = '') {
  let match = false;
  marketingPrograms.some(program => {
    if (path.toUpperCase().indexOf(program.toUpperCase()) > -1) {
      match = program;
      return true;
    }
    return false;
  });
  return match;
}

module.exports = {
  marketingPrograms,
  getProgramFromPath,
};
