import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { Container } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import PrevButton from '../Buttons/Previous';
import NextButton from '../Buttons/Next';

const ButtonContainer = withStyles({
  root: {
    marginTop: "2em",
    width: '100%',
    '& .navButtonPrev': {
      display: "inline",
      float: "left",
      width: "100px"
    },
    '& .navButtonNext': {
      display: "inline",
      float: "right",
      width: "100px"
    },
  }
})(Container);


class NavigationControls extends Component {
  render() {
    const {
      nextLabel = '',
      prevLabel = '',
    } = this.props;

    const defaultNextHandler = () => this.props.allowNext();
    const defaultPrevHandler = () => this.props.allowPrev();

    const nextClickHandler = this.props.onNextClick || this.props.onButtonClick || defaultNextHandler;
    const prevClickHandler = this.props.onPrevClick || this.props.onButtonClick || defaultPrevHandler;

    return (
      <ButtonContainer>
        <div className="navButtonPrev">
          <PrevButton
            nav={this.props.nav}
            denyPrev={this.props.denyPrev}
            changeRoute={this.props.changeRoute}
            location={this.props.location}
            onPrevClick={() => prevClickHandler()}>
            {prevLabel}
          </PrevButton>
        </div>
        <div className="navButtonNext">
          <NextButton
            nav={this.props.nav}
            denyNext={this.props.denyNext}
            changeRoute={this.props.changeRoute}
            location={this.props.location}
            useSubmitButton={this.props.useSubmitButton}
            onNextClick={() => nextClickHandler()}>
            {nextLabel}
          </NextButton>
        </div>
      </ButtonContainer>
    );
  }
}

NavigationControls.propTypes = {
  changeRoute: PropTypes.func,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  nav: PropTypes.shape({
    allowNext: PropTypes.bool,
    allowPrev: PropTypes.bool,
  }),
  nextLabel: PropTypes.string,
  prevLabel: PropTypes.string,
  allowNext: PropTypes.func,
  allowPrev: PropTypes.func,
  denyNext: PropTypes.func,
  denyPrev: PropTypes.func,
  onButtonClick: PropTypes.func,
  onNextClick: PropTypes.func,
  onPrevClick: PropTypes.func,
}

export default NavigationControls;
