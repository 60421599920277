import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
const overlay = {
  position: 'fixed',
  width: '100%',
  height: '100%',
  top: '0',
  bottom: '0',
  right: '0',
  left: '0',
  backgroundColor: 'rgba(0,0,0,0.5)',
  zIndex: '2',
  display: 'flex',
  justifyContent: 'center',
}

const containerStyle = {
  position: 'absolute',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  top: '45%',
  zIndex: '3',
  backgroundColor: "#FFFFFF",
  width: '18em',
  height: '15em',
  borderRadius: '0.35em',
}

const circularContainer = {
  width: '100%',
  display: 'flex',
  flexBasis: '100%',
  justifyContent: 'center',
  borderRadius: '0.35em',
}

const loadingStyle = {
  position: 'relative',
  top: '1.75em',
  display: 'block',
  width: '70px',
  height: '70px'
}

const percentStyle = {
  display: 'block',
  flexBasis: '100%',
  textAlign: 'center',
  height: '1.2em',
  fontSize: '2.20em',
  color: '#00306F',
}

const messageStyle = {
  display: 'block',
  textAlign: 'center',
  fontSize: '1.50em',
  color: '#00306F',
}

const ProgressLoading = ({ active = false, percent = 0, message = '' }) => {
  if (!active) {
    return null;
  }

  return (
    <div style={overlay}>
      <div style={containerStyle}>
        <div style={circularContainer}>
          <CircularProgress style={loadingStyle} />
        </div>
        <div style={percentStyle}>{percent}%</div>
        <div style={messageStyle}>{message}</div>
      </div>
    </div>
  )
}

export default ProgressLoading;