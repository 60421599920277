import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Container, Slider } from '@material-ui/core';

import { getFlowOrder, getFlowOrderIndex, getLabelFromRoutePath } from '../../routes';

import pencil from '../../assets/pencil_icon.svg'
import checkmark from '../../assets/checkmark_icon.svg'


const NaviContainer = withStyles({
  root: {
    width: '60%',
    '@media only screen and (max-width: 855px)': {
      width: '80%',
    },
    '@media only screen and (max-width: 600px)': {
      width: '100%',
    },
  }
})(Container);

const ProgressSlider = withStyles({
  root: {
    height: 4,
  },
  thumb: {
    height: 30,
    width: 30,
    marginTop: -16.5,
    marginLeft:-16,
     backgroundColor: 'currentColor',
    '&:focus,&:hover,&$active': {
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {

  },
  rail: {
    borderRadius: 4,
    color: 'gray'
  },
  mark: {
    height: 32,
    width: 32,
    marginTop: -18.5,
    marginLeft:-18.5,
    color: 'white',
    borderRadius: 50,
    border: '2px solid #00306f',
    

    
    '&:hover':{
    backgroundColor:"rgb(0, 48, 111)",
    backgroundImage: `url(${pencil})`,
    backgroundSize:'65% 65%',
    backgroundRepeat:'no-repeat',
    backgroundPosition:'center',
    }, 



},

  markActive: {
   padding:'',
    opacity: 1,
    backgroundColor:"rgb(0, 48, 111)",
    backgroundImage: `url(${checkmark})`,
    backgroundSize:'65% 65%',
    backgroundRepeat:'no-repeat',
    backgroundPosition:'52% 32%',
    boxShadow: '0px 0px 0px 2px rgb(0, 48, 111)',
    border:'2px solid #fff'
  },


  markLabel: {
    marginTop:'10px',
    fontSize: '12px',
    color:"rgb(0, 48, 111)",
    '@media only screen and (max-width: 600px)': {
      fontSize: '9px'
    },
    '@media only screen and (max-width: 380px)': {
      fontSize: '8px'
    }
  }
})(Slider);

function generateMarks() {
  // Get the flow order array from routes
  const flowOrder = getFlowOrder();

  // Remove the first and last entries since these are special end cases
  flowOrder.shift()
  flowOrder.pop();

  return flowOrder.map((path, index) => {
    return {
      value: index,
      label: getLabelFromRoutePath(path),
      path,
    }
  });
}

function getStartingValue(currentPath = '') {
  return getFlowOrderIndex(currentPath) - 1;
}

class NavigationBar extends Component {
  // Declare marks as static so they persist between mount/unmount cycle
  static marks = [];

  static valueLabelFormat(value) {
    return NavigationBar.marks.findIndex(mark => mark.value === value) + 1;
  }

  componentDidMount() {
    // Perform a one-time load of marks based on routes
    if (NavigationBar.marks.length === 0) {
      NavigationBar.marks = generateMarks();
      this.forceUpdate();
    }
  }
  handleSliderChange(value) {
    this.props.onNavigation();
    const navMark = NavigationBar.marks[value];
    const navRoute = navMark.path;
    this.props.changeRoute(navRoute);
  }

  render() {
    const { location = {} } = this.props;
    let startingValue = getStartingValue(location.pathname);
    return (
      <div className="NavigationBar NavigationBarText" style={{ width: "100%" }} >
      <NaviContainer>
        <div style={{ display: "inline-block", position: "relative", width: "15%", top: "-28px" }}>Begin</div>
        <div style={{ display: "inline-block", width: "70%" }}>
          <ProgressSlider
            id="navigation_slider"
            name="navigation_slider"
            defaultValue={startingValue}
            valueLabelFormat={NavigationBar.valueLabelFormat}
            aria-labelledby="discrete-slider-restrict"
            step={null}
            valueLabelDisplay="off"
            marks={NavigationBar.marks}
            min={-1}
            max={4}
            onChangeCommitted={(event, value) => this.handleSliderChange(value)}
          />
        </div>
        <div style={{ display: "inline-block", position: "relative", width: "15%", top: "-28px", }}>Finish</div>
        <div className = "timeRequired" style ={{ color:'#797D7F',width:'45%',display:'flex',position:'relative', justifyContent:'space-around', fontSize:'60%',marginLeft:'20%' }}>
            <div className = 'backgroundTime'style={{ marginLeft:'5%' , fontSize:'9px'}}><span>5 Minutes</span></div>
            <div className = 'excercise1Time'style={{ marginLeft:'1%', position:'relative', right:'1%', fontSize:'9px' }}><span>15 Minutes</span></div>
            <div className = 'excercise2Time' style ={{ marginLeft:'1%',position:'relative', right:'2%', fontSize:'9px' }}><span>35 Minutes</span></div>
        </div>
        </NaviContainer>
      </div>
    );
  }
}

NavigationBar.defaultProps = {
  changeRoute: () => { },
  location: { pathname: '' },
  onNavigation: () => { },
}

NavigationBar.propTypes = {
  changeRoute: PropTypes.func,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  onNavigation: PropTypes.func,
}

export default NavigationBar;
