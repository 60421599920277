function getQuestionError(pageDataObject, page, props) {
    if (!props) {
        return
    }

    let exercise = page.split('.')

    if (props.error) {
        if (pageDataObject.type === 'url'){
            pageDataObject[`${pageDataObject.key}ErrorValidWebsite`] = props[exercise[0]][exercise[1]][`${pageDataObject.key}ErrorValidWebsite`]
        }
        if (pageDataObject.type === 'email') {
            pageDataObject[`${pageDataObject.key}ErrorValidEmail`] = props[`${page}`][`${pageDataObject.key}ErrorValidEmail`]
        }
        if (pageDataObject.type === 'longAnswer') {
            pageDataObject[`${pageDataObject.key}ErrorLength`] = props[`${page}`][`${pageDataObject.key}ErrorLength`]
        }
        //covering edge case of embedded exercises within an object
        if (exercise.length > 1) {
            pageDataObject[`${pageDataObject.key}Error`] = props[exercise[0]][exercise[1]][`${pageDataObject.key}Error`]
        } else {
            pageDataObject[`${pageDataObject.key}Error`] = props[`${page}`][`${pageDataObject.key}Error`]
        }
        return {
            ...pageDataObject
        };
    }
}

export default getQuestionError;