import ActionTypes from './actionTypes';
import { getApiSubmissionUrl } from './helpers/api';

// action for final submission

export function submitFinal(submissionData) {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.SUBMISSION_LOADING
    });

    try {
      const apiSubmissionUrl = getApiSubmissionUrl()
      const result = await fetch(apiSubmissionUrl, {
        method: 'POST',
        body: JSON.stringify(submissionData)
      })

      if (result.ok) {
        const jsonResult = await result.json()
        console.log(jsonResult)
      }

      dispatch({
        type: ActionTypes.SUBMISSION_SUCCESS
      });

    } catch (error) {
      console.error(error)
      dispatch({
        type: ActionTypes.SUBMISSION_FAILURE, payload: error.message
      })
    }
  }
}