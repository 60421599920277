import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactS3Uploader from 'react-s3-uploader';
import { withStyles } from '@material-ui/core/styles';
import pageConnect from '../../redux/pageConnect';
import Helpers from '../../helpers';

import Card from '@material-ui/core/Card';
import Slider from '@material-ui/core/Slider';

import Check from '../../assets/check.svg';
import Error from '../../assets/error.svg';

import Header from '../../components/Header';
import ProgressLoading from '../../components/ProgressLoading';
import NavigationBar from '../../components/NavigationBar';
import ShortAnswerQuestion from '../../components/Questions/ShortAnswer';
import LongAnswerQuestion from '../../components/Questions/LongAnswer';
import NavigationControls from '../../components/NavigationControls';
import getQuestionError from '../../helpers/errorChecker';
import { getApiSignUrl } from '../../redux/actions/helpers/api';


const MAX_FILE_SIZE = 250000000; // 250 MB

const ExperienceSlider = withStyles({
  root: {
    height: 4,
  },
  thumb: {
    height: 16,
    width: 16,
    backgroundColor: 'currentColor',
    border: '1px solid #fff',
    marginTop: -4,
    marginLeft: -8,
    boxShadow: '0px 0px 0px 1px currentColor;',
    '&:focus,&:hover,&$active': {
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    display: 'none'
  },
  rail: {
    height: 4,
    borderRadius: 4,
    border: 'gray solid 2px',
    color: 'white'
  },
  mark: {
    display: 'none'
  },
  markLabel: {
    fontSize: '10px',
    '@media only screen and (max-width: 600px)': {
      width: '60px',
      whiteSpace: 'normal'
    }
  }
})(Slider);

const marks = [
  {
    value: 0,
    label: 'None at all',
  },
  {
    value: 1,
    label: 'I have creative hobbies',
  },
  {
    value: 2,
    label: 'I\'ve worked as a designer',
  },
];

function valueLabelFormat(value) {
  return marks.findIndex(mark => mark.value === value) + 1;
}

const backgroundQuestions = [
  {
    required: true,
    key: 'failTime',
    label: 'Describe a time you failed at something. How did you respond? ',
    placeholder: 'There was one time I worked on a very difficult project...',
    type: 'longAnswer'
  },
  {
    required: true,
    key: 'teamwork',
    label: 'How important is working with teams to you? Explain your answer.',
    placeholder: 'I find working with teams...',
    type: 'longAnswer'
  },
  {
    required: true,
    key: 'teamTroubles',
    label: `Think of a time you had frustrations working with another team member or 
            co-worker. How did you resolve the situation?`,
    placeholder: 'I found myself frustrated when...',
    type: 'longAnswer'
  },
]

class Background extends Component {
  constructor(props) {
    super(props)

    this.state = {
      videoUploading: false,
      videoUploadProgress: 0,
      videoUploadMessage: '',
    };
  }

  componentDidMount() {
    this.setVideoStateFromProps();
  }

  setVideoStateFromProps() {
    const background = this.props.background || {};
    const {
      videoName = '',
      videoSize = 0,
      videoType = '',
      videoUploaded = false,
      videoErrored = false,
      videoUuid = '',
    } = background;

    this.setState(prevState => ({
      ...prevState,
      videoName,
      videoSize,
      videoType,
      videoUploaded,
      videoErrored,
      videoUuid,
    }));
  }

  buildBackgroundData() {
    const background = {};
    backgroundQuestions.forEach((question) => {
      let item = document.getElementById(question.key).value
      background[question.key] = item;
      background[question.key + 'Error'] = !item ? true : false;
      background[question.key + 'ErrorLength'] = item.length >= 50 ? false : true;
    });

    const linkedIn = document.getElementById('linkedIn').value
    background.linkedIn = linkedIn;

    const {
      slider,
      videoName,
      videoSize,
      videoType,
      videoUploaded,
      videoErrored,
      videoUuid } = this.state;
    background.experience = slider;
    background.videoName = videoName;
    background.videoSize = videoSize;
    background.videoType = videoType;
    background.videoUploaded = videoUploaded;
    background.videoErrored = videoErrored;
    background.videoUuid = videoUuid;

    return background;
  }

  handleNavigation(direction, block = false) {
    if(document.activeElement.type !== 'textarea'){
      const background = this.buildBackgroundData();
      this.props.saveBackgroundData(background);
    }

    if (!block) {
      if (direction === 'next') {
        this.props.allowNext();
      } else if (direction === 'prev') {
        this.props.allowPrev();
      }
    }
  }

  onUploadStart(file, next) {
    // Grab the incoming filename
    if (file.size > MAX_FILE_SIZE) {
      this.setState(prevState => ({
        ...prevState,
        videoUploaded: false,
        videoErrored: true,
        videoErrorMessage: 'Your video file must be smaller than 250 MB!',
      }))
    } else {
      this.setState(prevState => ({
        ...prevState,
        videoName: file.name,
        videoSize: file.size,
        videoType: file.type,
        videoUploading: true,
        videoErrored: false,
        videoErrorMessage: '',
      }));
      next(file);
    }

  }

  onSignedUrl(data, error) {
    this.setState(prevState => ({
      ...prevState,
      videoUuid: data.uuid,
      videoUploaded: false,
      videoErrored: false,
      videoUploadProgress: 0,
      videoUploadMessage: '',
    }));
  }

  onUploadProgress(percent, message) {
    this.setState(prevState => ({
      ...prevState,
      videoUploadProgress: percent,
      videoUploadMessage: message,
    }));
  }

  onUploadError(data, error) {
    this.setState(prevState => ({
      ...prevState,
      videoUploaded: false,
      videoErrored: true,
      videoErrorMessage: 'Server Upload Error - Please Try Again',
      videoUploading: false,
    }));
  }

  onUploadFinish(data, error) {
    this.setState(prevState => ({
      ...prevState,
      videoUploaded: true,
      videoUploading: false,
    }));
  }

  getSliderValue() {
    const startValue = 'None at all';
    const propValue = Helpers.get(this.props, 'background.experience');
    const stringValue = this.state.slider || propValue || startValue;
    return marks.findIndex((mark) => mark.label === stringValue);
  }

  render() {
    const onBlur = (this.props.error ? () => this.handleNavigation('', true) : () => { });
    const program = Helpers.get(this.props, 'marketing.program', 'default');

    const sliderValue = this.getSliderValue();
    return (
      <div>
        <Header />
        <div className="Introduction">
          <NavigationBar
            changeRoute={this.props.changeRoute}
            location={this.props.location}
            onNavigation={() => this.handleNavigation()}
          />
          <Card className="Card">
            <div className="CardHeaderTextBackground">Background</div>

            {backgroundQuestions.map((question, index) => {
              let errors = getQuestionError(question, 'background', this.props)
              const value = Helpers.get(this.props, `background.${question.key}`);
              return (
                <LongAnswerQuestion
                  error={question}
                  object={errors}
                  key={`background_question_${index}`}
                  required={question.required}
                  questionKey={question.key}
                  label={question.label}
                  placeholder={question.placeholder}
                  value={value}
                  onBlur={onBlur}
                />
              )
            })}

            {/* The below could probably be turned into a slider question component */}
            <label htmlFor="design_experience_slider" className="InputLabel">
              How much art or design experience do you have?
            </label>
            <div style={{ width: "70%", paddingLeft: '15%', paddingTop: "6%", paddingBottom: "4%" }}>
              <ExperienceSlider
                id="design_experience_slider"
                name="design_experience_slider"
                defaultValue={0}
                valueLabelFormat={valueLabelFormat}
                aria-labelledby="discrete-slider-restrict"
                step={null}
                valueLabelDisplay="off"
                marks={marks}
                min={0}
                max={2}
                onChange={(e, val) => {
                  this.setState({
                    slider: marks[val].label
                  })
                }}
                value={sliderValue}
              />
            </div>

            <div style={{ paddingBottom: "2%" }}>
              <ShortAnswerQuestion
                error={null}
                helperText={null}
                onBlur={onBlur}
                required={false}
                questionKey={"linkedIn"}
                label={"Please provide a link to your LinkedIn profile."}
                placeholder={"https://linkedin.com/in/your-username"}
                value={Helpers.get(this.props, 'background.linkedIn', "")}
              />
            </div>

            {program.toUpperCase() !== 'UCSD' ? null : (
              <div className="VideoUploadSection">
                <ProgressLoading
                  active={this.state.videoUploading}
                  percent={this.state.videoUploadProgress}
                  message={this.state.videoUploadMessage}
                />
                <div className="VideoUploadDescription">
                  Upload a short video introducting yourself.
                  <ul>
                    <li>Tell us a little about you - why do you want to get into tech?</li>
                    <li>Short and sweet - should only be a few minutes (5 minutes max)</li>
                  </ul>
                  <div className="VideoUploadFormats">
                    Formats: .mp4, .mov, .avi, .wmv
                    <br />
                    Max Size: 250 MB
                  </div>
                </div>
                <div className="VideoUploadContainer">
                  <label className="VideoUploadButtonLabel" htmlFor="VideoUploadInput">Upload File</label>
                  <span className="VideoUploadFilename">
                    {this.state.videoName}

                    {!this.state.videoUploaded ? null : (
                      <img alt="Success" src={Check} className="VideoUploadStatusImage" />
                    )}

                    {!this.state.videoErrored ? null : (
                      <img alt="Success" src={Error} className="VideoUploadStatusImage" />
                    )}
                  </span>
                  <ReactS3Uploader
                    id="VideoUploadInput"
                    signingUrl={getApiSignUrl()}
                    signingUrlMethod="GET"
                    accept="video/*"
                    // s3path="/videos/"
                    preprocess={(data, error) => this.onUploadStart(data, error)}
                    onSignedUrl={(data, error) => this.onSignedUrl(data, error)}
                    onProgress={(percent, message) => this.onUploadProgress(percent, message)}
                    onError={(data, error) => this.onUploadError(data, error)}
                    onFinish={(data, error) => this.onUploadFinish(data, error)}
                    // signingUrlHeaders={{ additional: headers }}
                    // signingUrlQueryParams={{ additional: query-params }}
                    // signingUrlWithCredentials={ true }      // in case when need to pass authentication credentials via CORS
                    uploadRequestHeaders={{}}
                    contentDisposition="auto"
                    scrubFilename={(filename) => filename.replace(/[^\w\d_\-.]+/ig, '')}
                    // server="http://cross-origin-server.com"
                    // inputRef={cmp => this.uploadInput = cmp}
                    autoUpload={true}
                  />
                  {!this.state.videoErrored ? null : (
                    <div className="ReviewPageLinks" style={{ marginTop: '0.5em' }}>
                      {this.state.videoErrorMessage}
                    </div>
                  )}
                </div>
              </div>
            )}

            <NavigationControls
              nav={this.props.nav}
              changeRoute={this.props.changeRoute}
              allowNext={this.props.allowNext}
              allowPrev={this.props.allowPrev}
              denyNext={this.props.denyNext}
              denyPrev={this.props.denyPrev}
              location={this.props.location}
              nextLabel="Next"
              prevLabel="Previous"
              onNextClick={() => this.handleNavigation('next')}
              onPrevClick={() => this.handleNavigation('prev')}
            />
          </Card>
        </div>
      </div>

    );
  }
}

Background.propTypes = {
  background: PropTypes.object,
  changeRoute: PropTypes.func,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
}

export default pageConnect(Background);
