import { Provider } from 'react-redux';
import React from 'react'
import ReactDOM from 'react-dom';
import App from './App';
import { history, store } from './redux/createStore';

const render = () => {
  ReactDOM.render(
    <Provider store={store}>
      <App history={history} />
    </Provider>,
    document.getElementById('root')
  );
};

render();

/*
// Hot reloading
if (module.hot) {
  // Reload components
  module.hot.accept('./App', () => {
    render();
  });

  // Reload reducers
  module.hot.accept('./reducers', () => {
    store.replaceReducer(connectRouter(history)(rootReducer))
  });
}
*/