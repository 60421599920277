import React from 'react';
import { Route, Switch } from 'react-router';

import Introduction from './pages/Introduction';
import Background from './pages/Background';
import AppCritique from './pages/Exercises/AppCritique';
import Whiteboarding from './pages/Exercises/Whiteboarding';
import Review from './pages/Review';
import Finished from './pages/Finished';
import NotFound from './pages/NotFound';

import { marketingPrograms, getProgramFromPath } from './helpers/marketing';

const baseExercisePath = '/exercise';

const exerciseComponentOrder = [
  AppCritique,
  Whiteboarding,
];

const exerciseOrder = exerciseComponentOrder.map((value, index) => {
  return `${baseExercisePath}${index + 1}`;
});

const flowOrder = [
  '/',
  '/background',
  ...exerciseOrder,
  '/review',
  '/finished',
];

const routes = (
  <Switch>
    <Route exact path='/' render={(props) => (
      <Introduction {...props} />
    )} />
    {marketingPrograms.map((program, index) => (
      <Route path={`/${program}`} key={`program_key_${index + 1}`} render={(props) => (
        <Introduction {...props} />
      )} />
    ))}
    <Route path="/background" component={Background} />
    {exerciseComponentOrder.map((component, index) => (
      <Route path={`${baseExercisePath}${index + 1}`} component={component} key={`exercise_key_${index + 1}`} />
    )
    )}
    <Route path="/review" component={Review} />
    <Route path="/finished" component={Finished} />
    <Route component={NotFound} />
  </Switch>
);

function getFlowOrderPath(currentPath, arrayIndexChange, defaultPath = '/') {
  const currentIndex = getFlowOrderIndex(currentPath);
  if (currentIndex === -1) {
    return defaultPath;
  }

  const newPath = flowOrder[currentIndex + arrayIndexChange];

  return (newPath ? newPath : defaultPath);
}

// Return a copy of the flowOrder array so as to disallow
// modifications elsewhere that could impact routes
export function getFlowOrder() {
  return [...flowOrder];
}

export function getFlowOrderIndex(currentPath = '') {
  let indexPath = currentPath;
  if (getProgramFromPath(currentPath)) {
    indexPath = '/';
  }
  return flowOrder.indexOf(indexPath);
}

export function getLabelFromRoutePath(path = '') {
  let label = path.substring(1); // Remove path / at start
  label = label.charAt(0).toUpperCase() + label.substr(1); // Capitalize the first character
  if (label.indexOf('Exercise') > -1) {
    label = label.replace('Exercise', 'Exercise ');
  }
  return label;
}

export function getPrevPath(currentPath = '') {
  const defaultPrevPath = flowOrder[0];
  return getFlowOrderPath(currentPath, -1, defaultPrevPath);
}

export function getNextPath(currentPath = '') {
  const defaultNextPath = flowOrder[flowOrder.length - 1];
  return getFlowOrderPath(currentPath, 1, defaultNextPath);
}

export default routes;
