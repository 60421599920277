import React, { Component } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

const loadingStyle = {
    position: 'absolute',
    top: '50%',
    left: '47%',
    width: '70px',
    height: '70px'
  }
  
  const overlay = {
    position: 'fixed',
    width: '100%', 
    height: '100%', 
    top: '0',
    bottom: '0',
    right: '0',
    left: '0',
    backgroundColor: 'rgba(0,0,0,0.5)',
    zIndex: '2',
  }

  class Loading extends Component {
      render(){
          return(
            <div style={overlay}>
                <CircularProgress style={loadingStyle}/>
            </div>
          )
      }
  }

  export default Loading;