import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { getNextPath } from '../../../routes';
import Helpers from '../../../helpers';


import { DefaultButton, SubmitButton } from '../Default';

class NextButton extends Component {


  handleNextButtonClick() {
    this.props.onNextClick();
  }

  navigateNext() {
    const currentPath = Helpers.get(this.props, 'location.pathname');
    const nextPath = getNextPath(currentPath);
    this.props.changeRoute(nextPath);
  }

  componentDidUpdate() {
    const allowNext = Helpers.get(this.props, 'nav.allowNext', false);
    if (allowNext) {
      this.props.denyNext();
      this.navigateNext();
    }
  }

  render() {
    const { useSubmitButton, children } = this.props
    return (
      <div>
        {useSubmitButton ?
          <SubmitButton
            nav={this.props.nav}
            onButtonClick={() => this.handleNextButtonClick()}>
            {children}
          </SubmitButton> :
          <DefaultButton
            nav={this.props.nav}
            onButtonClick={() => this.handleNextButtonClick()}>
            {children}
          </DefaultButton>

        }
      </div>
    );
  }
}

NextButton.propTypes = {
  changeRoute: PropTypes.func,
  onNextClick: PropTypes.func,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  nav: PropTypes.shape({
    allowNext: PropTypes.bool,
    allowPrev: PropTypes.bool,
  }),
  denyNext: PropTypes.func,
}

export default NextButton;
