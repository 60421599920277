import ActionTypes from '../actions/actionTypes'


export default (state = {}, action = {}) => {
    switch (action.type) {
        case ActionTypes.IMAGE_UPLOAD_LOADING:
            return {
                ...state,
                imagesLoading: true,
                imagesError: null
            }
        case ActionTypes.IMAGE_UPLOAD_SUCCESS:
            return {
                ...state,
                images: action.payload,
                imagesLoading: false
            }
        case ActionTypes.IMAGE_UPLOAD_FAILURE:
            return { 
                ...state,
                imagesError: action.payload,
                imagesLoading: false
            }
        default:
            return state
    }
}