import React, { Component } from "react";
import WarningImage from "../../assets/UXAssessmentWarning.svg";
import pageConnect from "../../redux/pageConnect";

class ValidationError extends Component {
  constructor(props) {
    super(props);
    this.handleErrorNavigation = this.handleErrorNavigation.bind(this);
  }

  handleErrorNavigation = (event, location) => {
    event.preventDefault();
    this.props.changeRoute(location);
  };

  render() {
    const { errorItems } = this.props;
    return (
      <div>
        {this.props.error ? (
          <div style={{ display: "flex", alignItems: "flex-start" }}>
            {" "}
            <img
              alt="warning"
              style={{ paddingRight: "10px" }}
              src={WarningImage}
            ></img>{" "}
            <div style={{ color: "#FB7F59", fontSize: "11px" }}>
              Application incomplete. We still need something from you on:{" "}
            </div>{" "}
          </div>
        ) : (
          ""
        )}
        {(errorItems.applicant.length > 0 ||
          errorItems.errUndef.includes("applicant")) && (
          <div
            className="ReviewPageLinks"
            onClick={event => {
              this.handleErrorNavigation(event, "/");
            }}
          >
            The Welcome Page{" "}
          </div>
        )}
        {(errorItems.background.length > 0 ||
          errorItems.errUndef.includes("background")) && (
          <div
            className="ReviewPageLinks"
            onClick={event => {
              this.handleErrorNavigation(event, "/background");
            }}
          >
            The Background Page{" "}
          </div>
        )}
        {(errorItems.exercises.appCritique.length > 0 ||
          errorItems.errUndef.includes("exercises")) > 0 && (
          <div
            className="ReviewPageLinks"
            onClick={event => {
              this.handleErrorNavigation(event, "/exercise1");
            }}
          >
            The App Critique Page{" "}
          </div>
        )}
        {(errorItems.images.length > 0 ||
          errorItems.errUndef.includes("images")) && (
          <div
            className="ReviewPageLinks"
            onClick={event => {
              this.handleErrorNavigation(event, "/exercise2");
            }}
          >
            The Whiteboarding Page{" "}
          </div>
        )}
      </div>
    );
  }
}

export default pageConnect(ValidationError);
