import PropTypes from "prop-types";
import React, { Component } from "react";
import pageConnect from "../../redux/pageConnect";
import Helpers from "../../helpers";

import Card from "@material-ui/core/Card";

import Header from "../../components/Header";
import ShortAnswerQuestion from "../../components/Questions/ShortAnswer";
import NextButton from "../../components/Buttons/Next";
import getQuestionError from "../../helpers/errorChecker";
import { validateEmail } from "../../helpers/validation";


const applicantQuestions = [
  {
    required: true,
    key: "firstName",
    label: "First Name",
    placeholder: "First Name"
  },
  {
    required: true,
    key: "lastName",
    label: "Last Name",
    placeholder: "Last Name"
  },
  {
    required: true,
    type: "email",
    key: "emailAddress",
    label: "Email Address",
    placeholder: "myemail@email.com"
  }
];

class Introduction extends Component {
  state = {
    queryParams: {
      firstName: "",
      lastName: "",
      emailAddress: "",
      advisor: "",
      advisorEmail: ""
    },
  };

  componentDidMount() {
    this.updateQueryParamsState();
  }

  updateQueryParamsState() {
    // Look at URL query string for name and email
    const params = this.props.location.search;
    const searchParams = new URLSearchParams(params);
    searchParams.forEach((value, key) => {
      this.setState(prevState => ({
        queryParams: {
          ...prevState.queryParams,
          [key]: value
        }
      }));
    });
  }

  handleNavigation(block) {
    const applicant = {};
    const { advisor, advisorEmail } = this.state.queryParams;

    applicantQuestions.forEach(question => {
      let item = document.getElementById(question.key).value;
      applicant[question.key] = item;
      applicant[`${question.key}Error`] = !item ? true : false;
      applicant[`${question.key}ErrorLength`] =
        item.length <= 100 && item.length > 0 ? false : true;
      if (question.type === "email") {
        applicant[`${question.key}ErrorValidEmail`] = validateEmail(item);
      }
    });

    applicant.advisor = advisor;
    applicant.advisorEmail = advisorEmail;

    this.props.saveApplicantData(applicant);

    if (!block) {
      this.props.allowNext();
    }
  }

  render() {
    const { queryParams } = this.state;
    const program = (this.props.marketing && this.props.marketing.program) || "default";
    const onBlur = this.props.error
      ? () => this.handleNavigation(true)
      : () => { };

    return (
      <div>
        <Header />
        <div className="Introduction">
          <Card className="Card">
            <div className="CardHeaderText">Hello!</div>
            <p className="ParagraphText">
              {(program.toUpperCase() === "UCSD") ? 
              'Thank you for applying to UC San Diego Extension. The UX Skills Check is used to determine your eligibility for the UX Design Program.' 
              :
              'Thank you for applying to Kenzie Academy. The UX Skills Check is used to determine your eligibility for the UX Design Program.'
              }
            </p>

            <p className="ParagraphText">
              No prior design experience is required.
            </p>
            {(this.props.marketing && this.props.marketing.program.toUpperCase() === 'UCSD')  &&
              <p className="ParagraphText">
                Your response must be in English.
              </p>
            }
            <br />

            <p className="ParagraphText">
              <span style={{ fontWeight: "bold" }}>There is no time limit.</span> This assessment
              takes one hour to complete, on average.
            </p>

            <p className="ParagraphText" style={{ paddingBottom: '40px' }}>
              You have three days to complete this assessment from the time you receive it.
            </p>

            <br />

            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
              }}
            >
              {applicantQuestions.map((question, index) => {
                let errors = getQuestionError(
                  question,
                  "applicant",
                  this.props
                );
                const value =
                  queryParams[question.key] ||
                  Helpers.get(this.props, `applicant.${question.key}`);
                return (
                  <div
                    style={
                      question.key === "emailAddress"
                        ? { flexGrow: 1 }
                        : { width: "45%" }
                    }
                    key={`applicant_question_${index}`}
                  >
                    <ShortAnswerQuestion
                      error={
                        this.props.error
                          ? errors[`${question.key}Error`] ||
                          errors[`${question.key}ErrorValidEmail`]
                          : false
                      }
                      helperText={
                        this.props.error
                          ? (errors[`${question.key}Error`] && "Required") ||
                          (errors[`${question.key}ErrorValidEmail`] &&
                            "enter a valid email")
                          : ""
                      }
                      key={`applicant_question_${index}`}
                      onBlur={onBlur}
                      required={question.required}
                      questionKey={question.key}
                      label={question.label}
                      placeholder={question.placeholder}
                      value={value}
                    />
                  </div>
                );
              })}
            </div>

            <div style={{ textAlign: "center", width: "100%" }}>
              <div
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginTop: "4em",
                  marginBottom: "4em",
                  width: "150px"
                }}
              >
                <NextButton
                  nav={this.props.nav}
                  denyNext={this.props.denyNext}
                  className="center"
                  changeRoute={this.props.changeRoute}
                  location={this.props.location}
                  onNextClick={() => this.handleNavigation()}
                >
                  LET'S BEGIN
                </NextButton>
              </div>
            </div>
          </Card>
        </div>
      </div>
    );
  }
}

Introduction.propTypes = {
  applicant: PropTypes.object,
  changeRoute: PropTypes.func,
  location: PropTypes.object,
  saveApplicantData: PropTypes.func
};

export default pageConnect(Introduction);
