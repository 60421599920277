import React from 'react';
import { useSelector } from "react-redux";

import Default from './Default';
import UCSD from './UCSD';


const Header = () => {
  const marketing = useSelector(state => state.root && state.root.marketing);
  const program = (marketing && marketing.program) || "default";

  if (program.toUpperCase() === 'UCSD') {
    return (<UCSD />);
  } else {
    return (<Default />);
  }
}

export default Header;