import PropTypes from 'prop-types';
import React, { Component } from 'react';
import pageConnect from '../../../redux/pageConnect';

import Card from '@material-ui/core/Card';

import Header from '../../../components/Header';
import NavigationBar from '../../../components/NavigationBar';
import NavigationControls from '../../../components/NavigationControls';
import { DropzoneArea } from 'material-ui-dropzone'

class Whiteboarding extends Component {
  handleNavigation(direction) {
    if (direction === 'next') {
      this.props.allowNext();
    } else if (direction === 'prev') {
      this.props.allowPrev();
    }
  }

  // function handles saving images from dropzone

  handleChange(files, direction) {
    const items = {
      files
    }
    if (files.length > 0) {
      items['ImageError'] = false
    }
    if (files.length === 0) {
      items['ImageError'] = true
    }
    this.props.saveImagesData({ ...items });
  }

  render() {
    return (
      <div>
        <Header />
        <div className="Introduction">
          <NavigationBar
            changeRoute={this.props.changeRoute}
            location={this.props.location}
            onNavigation={() => this.handleNavigation()}
          />
          <Card className="Card" style={{ paddingTop: "60px" }}>
            <div className="CardHeaderText">Whiteboarding</div>
            <span>Key Skills: storytelling, empathy</span>
            <br />
            <span style={{ fontSize: "14px" }}>~35 minutes</span>

            <p className="ParagraphText" style={{ paddingTop: "20px", paddingBottom: "20px" }}>
              Design interviews often feature whiteboarding sessions.
              Whiteboarding is a great way to explore ideas quickly.
              Despite its name, you can whiteboard with any drawing tool.
              Use a pen and paper or any digital design tool to sketch
              out idea for the following design prompt.
            </p>

            <div className="SubHeaderText">Scenario:</div>
            <p className="ParagraphText">
              A major bank wants to promote financial education for pre-teens.
              They want to build an ATM for children ages 8-12.
            </p>
            <br></br>
            <div className="SubHeaderText">Consider:</div>
            <ul style={{ paddingLeft: "18px" }}>
              <li style={{ paddingTop: "4px", paddingBottom: "4px" }}>How would an ATM for pre-teens differ from a regular ATM? </li>
              <li style={{ paddingTop: "4px", paddingBottom: "4px" }}>What’s the most important thing to consider, when designing
              an ATM for this age group?</li>
              <li style={{ paddingTop: "4px", paddingBottom: "4px" }}>What security measures would there
              be?</li>
              <li style={{ paddingTop: "4px", paddingBottom: "4px" }}>What are some potential downsides to your solution?</li>
              <li style={{ paddingTop: "4px", paddingBottom: "4px" }}>How
              can you measure the success of your solution, if it’s built?</li>
            </ul>
            <br></br>
            <p>Use notes and drawings to explain your ideas.</p>
            <br></br>

            <div className='dropzoneContainer'>
              <DropzoneArea
                onChange={this.handleChange.bind(this)}
                filesLimit={5}
                initialFiles={this.props.images && this.props.images.files ? this.props.images.files.map(x => {
                  return x.name
                }) : []}
                maxFileSize={5000000}
                dropzoneText={'Drag and drop up to five images here, or click to upload.'}
                showFileNames={true}
                useChipsForPreview={true}
              />
            </div>

            {this.props.error ?
              (!this.props.images || this.props.images.files.length === 0)
              &&
              <div style={{ color: '#FB7F59', paddingTop: '5px' }}>Please upload an image of your work.</div> : ''}

            <NavigationControls
              nav={this.props.nav}
              changeRoute={this.props.changeRoute}
              allowNext={this.props.allowNext}
              allowPrev={this.props.allowPrev}
              denyNext={this.props.denyNext}
              denyPrev={this.props.denyPrev}
              location={this.props.location}
              nextLabel="Next"
              prevLabel="Previous"
              onNextClick={() => this.handleNavigation('next')}
              onPrevClick={() => this.handleNavigation('prev')}
            />
          </Card>
        </div>
      </div>
    );
  }
}

Whiteboarding.propTypes = {
  changeRoute: PropTypes.func,
  exercises: PropTypes.shape({
    whiteboarding: PropTypes.object,
  })
}

export default pageConnect(Whiteboarding);
