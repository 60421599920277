import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { styled } from '@material-ui/core/styles';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

const MyTextArea = styled(TextareaAutosize)({
  border: '1px solid #CCCCCC',
  resize: 'none',
  borderRadius: 4,
  paddingTop: '8px',
  paddingBottom: '8px',
  fontSize: '12px',
  background: '#FAFAFA',
  // boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)',
  color: '#47495E',
  height: 48,
  '&::placeholder': {
    color: '#B3B3B3'
  }
});

const errorStyling = {
  color: '#FB7F59', 
  marginTop: '-24px', 
  paddingBottom: '10px', 
  fontSize: '11px'
}


class LongAnswer extends Component {
  handleTextFieldChange(value) {
    this.setState({ answer: value });
  }

  getAnswerValue() {
    const stateValue = (this.state || {}).answer;
    const propsValue = this.props.value;
    if(document.activeElement.type === 'textarea'){
      return stateValue || ''
    }
    return stateValue || propsValue || '';
  }

  render() {
    const {
      label = '',
      placeholder = '',
      questionKey,
      required = false,
      onBlur
    } = this.props;
    const value = this.getAnswerValue();
    return (
      <React.Fragment>
        <label htmlFor={questionKey} style={{fontSize: '13px'}} className="InputLabel">
          {label}
        </label>
        <MyTextArea
          style={this.props.error[`${this.props.error.key}Error`] || this.props.error[`${this.props.error.key}ErrorLength`] ? {border: 'solid #FB7F59 1px'} : {}}
          required={required}
          id={questionKey}
          label=""
          name={questionKey}
          rows="4"
          placeholder={placeholder}
          className="LongAnswerQuestion"
          value={value}
          onChange={(event) => this.handleTextFieldChange(event.target.value)}
          onBlur={onBlur}
        />
        {(this.props.error[`${this.props.error.key}Error`] && 
        <div style={errorStyling}>Required</div>)
        ||
        (this.props.error[`${this.props.error.key}ErrorLength`] &&
        <div style={errorStyling}>Please enter at least 50 characters.</div>)
        }
      </React.Fragment>
    );
  }
}

LongAnswer.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  questionKey: PropTypes.string,
  required: PropTypes.bool,
}

export default LongAnswer;
